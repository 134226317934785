<template>
<div>
    <!-- 客户增加 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">

                <el-form  :model="form_data" label-width="140px" style="margin-bottom: 12px;">
                    <el-form-item label="账号">
                        <el-input v-model="form_data.cpid" placeholder="请输入账号" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="公司">
                        <el-select v-model="form_data.company_id" filterable clearable  size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in companyList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="登录密码">
                        <el-input v-model="form_data.login_pwd" placeholder="请输入登录密码" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="接口密码">
                        <el-input v-model="form_data.cppwd" placeholder="请输入接口密码" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="联系方式">
                        <el-input v-model="form_data.relationphone" placeholder="选填,多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="付费类型">
                        <el-radio-group v-model="form_data.user_type" size="mini" >
                            <el-radio :label="0">预付费</el-radio>
                            <el-radio :label="1">后付费</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="失败是否返还">
                        <el-radio-group v-model="form_data.fail_rtid" size="mini" >
                            <el-radio label="no">返还</el-radio>
                            <el-radio label="yes">不返还</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="客户鉴权IP">
                        <el-input v-model="form_data.ip" placeholder="多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="客户接入方式">
                        <el-radio-group v-model="form_data.interface_type" size="mini" >
                            <el-radio :label="0">CMPP</el-radio>
                            <el-radio :label="1">HTTP</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="http对接协议" v-show="form_data.interface_type == 1">
                        <el-select v-model="form_data.qxt_http_type" filterable clearable  size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in httpTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="私钥" v-show="form_data.interface_type == 1">
                        <el-input v-model="form_data.secret" placeholder="选填,不填表示使用系统默认值" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="回调地址" v-show="form_data.interface_type == 1">
                        <el-input v-model="form_data.url" placeholder="请填写回调地址" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="上行地址" v-show="form_data.interface_type == 1">
                        <el-input v-model="form_data.mourl" placeholder="请填写上行地址" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="余额提醒" v-show="form_data.user_type == 0">
                        <el-radio-group v-model="form_data.blanceCallNum_onoff" size="mini" @change="form_data.blanceCallNum=''">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="余额提醒阈值" v-show="form_data.blanceCallNum_onoff == 1 && form_data.user_type == 0">
                        <el-input v-model="form_data.blanceCallNum" placeholder="请填写余额提醒阈值" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="余额提醒号码" v-show="form_data.blanceCallNum_onoff == 1 && form_data.user_type == 0">
                        <el-input v-model="form_data.phones" placeholder="多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input v-model="form_data.remark" placeholder="" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="未知返还比例">
                        <el-input v-model="form_data.unknownReturn" placeholder="请输入未知返还比例" size="mini" style="vertical-align: middle;">
                            <template slot="append" >%</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="客户类型">
                        <el-radio-group v-model="form_data.yld_level" size="mini" >
                            <el-radio :label="1">验证码</el-radio>
                            <el-radio :label="2">行业</el-radio>
                            <el-radio :label="3">营销</el-radio>
                            <el-radio :label="4">四类</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="行业发营销监控开关" v-show="form_data.yld_level==1 || form_data.yld_level==2">
                        <el-radio-group v-model="form_data.hy_send_yx_onoff" size="mini" >
                            <el-radio :label="1">关闭</el-radio>
                            <el-radio :label="0">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="行业发营销最低利润" v-show="form_data.hy_send_yx_onoff == 0 && (form_data.yld_level==1 || form_data.yld_level==2)" >
                        <el-input v-model="form_data.hy_send_yx_profit_money" placeholder="请输入最低利润" size="mini" oninput="value=value.replace(/[^\d.]/g,'')" style="vertical-align: middle;">
                            <template slot="append">分/条</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="回T退订" v-show="form_data.sms_type != 2">
                        <el-radio-group v-model="form_data.isT" size="mini" >
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="日限">
                        <el-radio-group v-model="form_data.isDayNum_onoff" size="mini" @change="form_data.isDayNum_onoff == 0 ? form_data.isDayNum = -1 : form_data.isDayNum = 5">
                            <el-radio :label="1">限制</el-radio>
                            <el-radio :label="0">不限</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="日限条数" v-show="form_data.isDayNum_onoff == 1">
                        <el-input v-model="form_data.isDayNum" placeholder="请输入日限条数" size="mini" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="号码限流">
                        <el-radio-group v-model="form_data.isminutetime_ononff" size="mini" @change="form_data.isminutetime_ononff == 0 ? form_data.isminutetime = 0 : form_data.isminutetime = 1">
                            <el-radio :label="0">不限</el-radio>
                            <el-radio :label="1">限制</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="号码限流时间" v-show="form_data.isminutetime_ononff == 1">
                        <el-select v-model="form_data.isminutetime"  size="mini" style="width:100%"> 
                            <el-option label="1分钟" :value="1"></el-option>
                            <el-option label="2分钟" :value="2"></el-option>
                            <el-option label="3分钟" :value="3"></el-option>
                            <el-option label="5分钟" :value="5"></el-option>
                            <el-option label="10分钟" :value="10"></el-option>
                            <el-option label="20分钟" :value="20"></el-option>
                            <el-option label="30分钟" :value="30"></el-option>
                            <el-option label="1小时" :value="60"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="号码限流条数" v-show="form_data.isminutetime_ononff == 1">
                        <el-input v-model="form_data.isminutenum" placeholder="请输入号码限流条数" size="mini" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item label="签名位置" v-show="form_data.sms_type != 2">
                        <el-select v-model="form_data.sign_position"  size="mini" style="width:100%"> 
                            <el-option label="强制前置" :value="0"></el-option>
                            <el-option label="强制后置" :value="1"></el-option>
                            <el-option label="不管签名位置" :value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="自动加签名" v-show="form_data.sms_type != 2">
                        <el-input v-model="form_data.sign_add" placeholder="空表示不加" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="双签名修改开关" v-show="form_data.sms_type != 2">
                        <el-radio-group v-model="form_data.isDoublesign" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="头尾双签名拒发开关" v-show="form_data.sms_type != 2">
                        <el-radio-group v-model="form_data.is_top_end_sign" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="验证码过滤词">
                        <el-radio-group v-model="form_data.yzmKeyword" size="mini" @change="form_data.yzmKeyword == 0 ? form_data.keywords_id_YZM_array=[] : ''">
                            <el-radio :label="0">不验证</el-radio>
                            <el-radio :label="1">包含转人工</el-radio>
                            <el-radio :label="2">包含拦截</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="验证码过滤词库" v-show="form_data.yzmKeyword != 0">
                        <el-select v-model="form_data.keywords_id_YZM_array" filterable clearable multiple  size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in kewyordGroupList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="账号过滤词">
                        <el-radio-group v-model="form_data.isKeyword" size="mini" @change="form_data.isKeyword == 0 ? form_data.cpidKeywordGroup_array=[] : ''">
                            <el-radio :label="0">不验证</el-radio>
                            <el-radio :label="1">包含转人工</el-radio>
                            <el-radio :label="2">包含拦截</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="账号过滤词库" v-show="form_data.isKeyword != 0">
                        <el-select v-model="form_data.cpidKeywordGroup_array" filterable clearable multiple  size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in kewyordGroupList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="自有黑名单">
                        <el-radio-group v-model="form_data.selfBlack" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="运营商黑名单">
                        <el-radio-group v-model="form_data.operBlack" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="回T退订黑名单">
                        <el-radio-group v-model="form_data.backBlack" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="三方黑名单">
                        <el-checkbox-group v-model="form_data.black_three_id_array_name" size="mini">
                            <el-checkbox v-for="item in blackThreeList" :label="item.name" :key="item.black_three_id" @change="blackThreeChange($event, item.black_three_id)"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="http长短信状态推送" v-show="form_data.interface_type == 1 && form_data.qxt_http_type == 'system'">
                        <el-radio-group v-model="form_data.isReportPush" size="mini">
                            <el-radio :label="0">单条</el-radio>
                            <el-radio :label="1">多条</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="http返回状态时间" v-show="form_data.interface_type == 1 && form_data.qxt_http_type == 'system'">
                        <el-radio-group v-model="form_data.push_state_time" size="mini">
                            <el-radio :label="0">不推送</el-radio>
                            <el-radio :label="1">推送</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="http推送计费条数" v-show="form_data.interface_type == 1 && form_data.qxt_http_type == 'system'">
                        <el-radio-group v-model="form_data.push_snum" size="mini">
                            <el-radio :label="0">不推送</el-radio>
                            <el-radio :label="1">推送</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="CMPP最大发送速度" v-show="form_data.interface_type == 0">
                        <el-input v-model="form_data.speedMax" placeholder="请填写CMPP最大发送速度" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                            <template slot="append">条/秒</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="发送类别">
                        <el-radio-group v-model="form_data.sms_type" size="mini">
                            <el-radio :label="0">短信</el-radio>
                            <el-radio :label="1">语音</el-radio>
                            <el-radio :label="2">多媒体短信</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="固话短信开关" v-show="form_data.sms_type == 0">
                        <el-radio-group v-model="form_data.fixed_line_onoff" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="固话短信可发省份" v-show="form_data.fixed_line_onoff == 1">
                        <el-checkbox-group v-model="form_data.fixed_line_province_array" size="mini">
                            <el-checkbox v-for="item in provList" :label="item.province" :key="item.id" @change="fixedLineProvinceChange($event, item.id)"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="自定义扩展码">
                        <el-radio-group v-model="form_data.own_ext_onoff" size="mini" @change="form_data.own_ext = ''">
                            <el-radio :label="0">不允许</el-radio>
                            <el-radio :label="1">允许</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="自定义扩展码" v-show="this.form_data.own_ext_onoff == 1">
                        <el-input v-model="form_data.own_ext" placeholder="请填写CMPP自定义扩展码" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="签名库">
                        <el-select v-model="form_data.sign_library_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in signLibraryList"
                                :key="item.sign_library_id"
                                :label="item.sign_name"
                                :value="item.sign_library_id"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="签名扩展类型" v-show="form_data.sign_library_id != 0">
                        <el-select v-model="form_data.sign_ext_onoff"  size="mini" style="width:100%"> 
                            <el-option label="使用签名库扩展" :value="0"></el-option>
                            <el-option label="使用签名库+客户扩展" :value="1"></el-option>
                            <el-option label="使用客户扩展" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="签名库自加签名" v-show="form_data.sign_library_id != 0">
                        <el-radio-group v-model="form_data.sign_after" size="mini" >
                            <el-radio :label="0">允许</el-radio>
                            <el-radio :label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>


                    <el-form-item label="移动">
                        <el-radio-group v-model="form_data.yd_refuse" size="mini">
                            <el-radio :label="0">可发</el-radio>
                            <el-radio :label="1">拒发</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="联通">
                        <el-radio-group v-model="form_data.lt_refuse" size="mini">
                            <el-radio :label="0">可发</el-radio>
                            <el-radio :label="1">拒发</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="电信">
                        <el-radio-group v-model="form_data.dx_refuse" size="mini">
                            <el-radio :label="0">可发</el-radio>
                            <el-radio :label="1">拒发</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="可发省份" >
                        <el-checkbox-group v-model="form_data.send_pro_array" size="mini">
                            <template v-for="item in provList" >
                                <el-checkbox :key="item.id" :label="item.province" :disabled="prov_city_select_map.has(item.id+'')" :indeterminate="prov_city_select_map.has(item.id+'')" @change="provinceChange($event, item.id)" style="margin-right: 0px;"><br></el-checkbox>
                                
                                <div :key="item.id+1000" class="cursor" style="font-size:14px;display: inline-block;position: relative;margin-right: 20px;" @click="selectCity(item.id,item.province)">{{item.province}}</div>
                            </template>
                            <!-- <el-checkbox v-for="item in provList" :label="item.province" :key="item.id" @change="provinceChange($event, item.id)"> </el-checkbox> -->
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="可发省份下的地市" v-show="prov_city_select_map.size >  0">
                         <template v-for="(value, key) in prov_city_select_map" >
                            <div :key="key">{{value[1].prov_name+"："+value[1].citys}}</div>
                        </template>
                    </el-form-item>

                    <el-form-item label="验证码识别开关" v-show="form_data.sms_type != 2">
                        <el-radio-group v-model="form_data.checknum_onoff" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="全局模板开关" v-show="form_data.sms_type != 2">
                        <el-radio-group v-model="form_data.all_template_onoff" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="强制不审核开关">
                        <el-radio-group v-model="form_data.ischeck" size="mini">
                            <el-radio :label="0">关闭</el-radio>
                            <el-radio :label="1">打开</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="客户接入码">
                        <el-input v-model="form_data.access_code" placeholder="选填" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="客户平台权限">
                        <el-checkbox-group v-model="form_data.user_power_array">
                            <el-checkbox label="自助下行导出"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="预警策略">
                        <el-select v-model="form_data.warning_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in warningList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="是否支持携号转网">
                        <el-radio-group v-model="form_data.change_oper_onoff" size="mini">
                            <el-radio :label="1">支持</el-radio>
                            <el-radio :label="0">不支持</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否允许带链接">
                        <el-radio-group v-model="form_data.link_is" size="mini">
                            <el-radio :label="0">允许</el-radio>
                            <el-radio :label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="带链接的信任级别" v-show="form_data.link_is == 0">
                        <el-radio-group v-model="form_data.link_trust_level" size="mini">
                            <el-radio :label="0">信任</el-radio>
                            <el-radio :label="1">不信任</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="客户重发策略">
                        <el-select v-model="form_data.user_repeat_strategy_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in repeat_strategyList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="要求扩展码位数">
                        <el-input v-model="form_data.ext_num" placeholder="请填写扩展码位数" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="上行是否要求" >
                        <el-radio-group v-model="form_data.mo_onoff" size="mini">
                            <el-radio :label="0">要求</el-radio>
                            <el-radio :label="1">不要求</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="特殊分类标签">
                        <el-select v-model="form_data.special_label" filterable size="mini"  placeholder="选填" style="width:100%"> 
                            <el-option
                                v-for="item in special_labelList"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="是否允许带联系方式" >
                        <el-radio-group v-model="form_data.contact_onoff" size="mini">
                            <el-radio :label="0">允许</el-radio>
                            <el-radio :label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="是否允许发送纯英文" >
                        <el-radio-group v-model="form_data.pure_english_onoff" size="mini">
                            <el-radio :label="0">允许</el-radio>
                            <el-radio :label="1">不允许</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="英文签名是否可发" >
                        <el-radio-group v-model="form_data.english_sign_onoff" size="mini">
                            <el-radio :label="0">不可发</el-radio>
                            <el-radio :label="1">可发</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="单条最低利润">
                        <el-input v-model="form_data.profit_min" placeholder="请填写单条最低利润" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="单日赔钱最大值">
                        <el-input v-model="form_data.day_pay_max" placeholder="请填写单日赔钱最大值" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">元</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="审核方式" >
                        <el-radio-group v-model="form_data.check_type" size="mini">
                            <el-radio :label="0">免审</el-radio>
                            <el-radio :label="1">必审</el-radio>
                            <el-radio :label="2">系统抽样审</el-radio>
                            <el-radio :label="3">无模板拒发</el-radio>
                            <el-radio :label="4">预报备模板</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="可发送时段">
                        <el-input v-model="form_data.send_hhmm_start_hh" placeholder="" style="width:100px;vertical-align: middle;" size="mini" @change="form_data.send_hhmm_start_hh >= '24' ? form_data.send_hhmm_start_mm='00' : '' " oninput="value=value.replace(/[^\d.]/g,'')">
                            <template slot="append">点</template>
                        </el-input>
                        <el-select v-model="form_data.send_hhmm_start_mm" :disabled="form_data.send_hhmm_start_hh >= '24'" size="mini" style="width:70px"> 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                        到
                        <el-input v-model="form_data.send_hhmm_end_hh" placeholder="" style="width:100px;vertical-align: middle;" size="mini" @change="form_data.send_hhmm_end_hh >= '24' ? form_data.send_hhmm_end_mm='00' : '' " oninput="value=value.replace(/[^\d.]/g,'')">
                            <template slot="append">点</template>
                        </el-input>
                        <el-select v-model="form_data.send_hhmm_end_mm" :disabled="form_data.send_hhmm_end_hh >= '24'" size="mini" style="width:70px" > 
                            <el-option label="整" value="00"></el-option>
                            <el-option label="半" value="30"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="超时段处理方式">
                        <el-radio-group v-model="form_data.send_timeout_set" size="mini" >
                            <el-radio :label="0">延迟发送</el-radio>
                            <el-radio :label="1">直接失败</el-radio>
                        </el-radio-group>
                    </el-form-item>

                     <el-form-item label="是否要求指定码号" >
                        <el-radio-group v-model="form_data.specify_codenum_onff" size="mini" @change="form_data.specify_codenum=''">
                            <el-radio :label="0">要求</el-radio>
                            <el-radio :label="1">不要求</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="指定码号" v-show="form_data.specify_codenum_onff == 0">
                        <el-input v-model="form_data.specify_codenum" placeholder="请填写指定码号" size="mini" oninput="value=value.replace(/[^\d.]/g,'')">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="灵狐审核类型" >
                        <el-radio-group v-model="form_data.fox_type" size="mini" @change="form_data.specify_codenum=''">
                            <el-radio :label="-1">不验证</el-radio>
                            <el-radio :label="0">极速</el-radio>
                            <el-radio :label="1">AI</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="灵狐抽样比率" v-show="form_data.fox_type != -1">
                        <el-input v-model="form_data.sampling_rate" placeholder="请填写灵狐抽样比率" size="mini" style="vertical-align: middle;" oninput="value=value.replace(/[^\d]/g,'')">
                            <template slot="append" >万分比（‱）</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="可选通道类别">
                        <el-checkbox-group v-model="form_data.channel_select_style_array" size="mini">
                            <el-checkbox  label="直连"></el-checkbox>
                            <el-checkbox  label="准直连"></el-checkbox>
                            <el-checkbox  label="三方"></el-checkbox>
                            <el-checkbox  label="厂商"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="发送类型">
                        <el-input v-model="form_data.send_type" placeholder="选填,例如：三网行业" size="mini">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="通道选取方式">
                        <el-radio-group v-model="form_data.channel_select_type" size="mini">
                            <el-radio :label="0">指定通道组</el-radio>
                            <el-radio :label="1">自动选通道</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="指定通道" v-show="form_data.channel_select_type == 0">
                        <el-select v-model="form_data.user_channel_id_array" filterable multiple clearable size="mini" style="width:100%"
                        :popper-append-to-body="false"
                        popper-class="el_select_text_align_right"
                        > 
                            <el-option
                                v-for="item in channelList"
                                :key="item.id"
                                :label="item.channel_name+'('+item.id+')'"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="移动价格">
                        <el-input v-model="form_data.yd_price" placeholder="请填写移动价格" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="联通价格">
                        <el-input v-model="form_data.lt_price" placeholder="请填写联通价格" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="电信价格">
                        <el-input v-model="form_data.dx_price" placeholder="请填写联通价格" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    



                    

                    
                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
                                    
                


            </el-row>
            

           
            

        </el-row>


    </el-dialog>

    <!--省市选择 组件 -->
    <ProvCitySelect :prop_change_i="prop_prov_city_select_i"
        :prop_item="prop_prov_city_select_item"
        @result="provCitySelectResult"
    ></ProvCitySelect>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import ProvCitySelect from '../../components/prov_city/prov_city_select.vue';//客户百分比通道组组件

//import axios from "axios";
export default {
    components: {
        ProvCitySelect
    },

    props:{
        prop_change_i:0,
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                
                this.dialog_visible = true;//弹出框是否可见 

                this.cur_title = "客户增加";
                setTimeout(() => {
                    //获取 http对接类别 列表数据
                    this.getHttpTypeDataList();

                    //获取过滤词库列表数据
                    this.getKewyordGroupList();

                    //获取签名库列表数据
                    this.getSignLibraryList();

                    //三方黑名单列表-不分页
                    this.getBlackThreeList();

                    //获取省份列表数据
                    this.getProvList();

                    //获取预警策略数据
                    this.getWarningList();

                    //获取重发策略数据
                    this.getRepeatStrategyList();

                    //获取特殊标签数据
                    this.getSpecialLabelList();

                    //获取通道列表数据-指定通道组使用的
                    this.getChanenlList();

                    //获取公司列表数据
                    this.getCompanyList();

                    //查询
                    this.getData();

                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            tableData_titil:[],//客户cpid+公司名
            kewyordGroupList:[],//过滤词库列表数据-请求接口获取
            blackThreeList:[],//三方黑名单列表-不分页
            signLibraryList:[],//签名库列表数据-请求接口获取
            provList:[],//省份列表数据-请求接口获取
            warningList:[],//预警策略数据-请求接口获取
            repeat_strategyList:[],//重发策略数据-请求接口获取
            special_labelList:[],//特殊标签数据-请求接口获取
            hourList:[{id:0},{id:1},{id:2},{id:3},{id:4},{id:5},{id:6},{id:7},{id:8},{id:9},{id:10},{id:11},{id:12},{id:13},{id:14},{id:15},{id:16},{id:17},{id:18},{id:19},{id:20},{id:21},{id:22},{id:23},{id:24}],//小时
            channelList:[],//通道列表数据-指定通道组使用的--请求接口获取
            companyList:[],//公司列表数据-请求接口获取
            httpTypeList:[],//http对接类别 列表数据  -请求接口获取

             //省市选择-弹出层
            prop_prov_city_select_i:0,//弹出框是否可见
            prop_prov_city_select_item:{},

            prov_city_select_map : new Map(),//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}

            form_data:{
                cpid:"",
                company_id:0,//公司id
                cppwd:"",//用户接口密码
                login_pwd:"",//用户登录密码
                secret:"",//私钥  选填，不填表示使用系统默认值
                relationphone:"",//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
                user_type:1,//用户付费类型0:预付费类型1：后付费类型
                fail_rtid:"",//失败状态返回到的cpid,空不返还
                ip:"",//客户鉴权ip地址(多个用半角逗号隔开)
                interface_type:0,//接入方式，0:CMPP；1:HTTP
                qxt_http_type:"system",//http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
                url:"",//回调地址
                mourl:"",//上行地址（即上行是回调用）
                blanceCallNum_onoff:0,//余额提醒开关，0：关闭；1：打开
                blanceCallNum:-1,//余额提醒  -1:不提醒，大于0则按照数目提醒  一级账号提醒的单位是分，二级和三级账号是条数
                phones:"",//余额提醒目标号码，多个用半角逗号隔开
                remark:"",//备注 ，在用户统计里显示

                unknownReturn:100,// 未知返还比例0：不返还；大于0小于等于100则按比例返还（新）
                yld_level:3,// 客户类型 ，默认为4，1：验证码； 2：行业通知；3：营销；4：四类
                hy_send_yx_onoff:1,//行业发营销监控开关  0:开；1:关
                hy_send_yx_profit_money:"0",//行业发营销监控打开时，最低利润，单位分
                isT:0,// 回T退订0：关闭；1：打开
                isDayNum_onoff:1,//日限开关 0：不限，1：限制
                isDayNum:5,// 日限次数-1：表示不限
                isminutetime_ononff:0,// 号码限流开关， 0：不限，1：限制
                isminutetime:0,// 号码限流，分钟单位，0:不限制 
                isminutenum:0,// 号码限流，分钟限制条数
                sign_position:0,// 签名位置：0：强制前置；1：强制后置；2：不管签名位置
                sign_add:"",//自动加签名，在下发内容上自动添加的签名，如为空表示不加
                isDoublesign:0,// 双签名修改开关，0:关闭；1:打开
                is_top_end_sign:0,//头尾双签名拒发开关，0:关闭；1:打开
                yzmKeyword:2,// 验证码过滤字 0：不验证；1：包含转人工；2：包含拦截
                isKeyword:1,// 过滤字0：不验证；1：包含转人工；2：包含拦截
                selfBlack:1,// 自有黑名单0：关闭；1：打开
                operBlack:1,// 运营商黑名单0：关闭；1：打开
                backBlack:1,// 回T退订黑名单0：关闭；1：打开
                black_three_id_array:"",//三方黑名单供应商ID，多个使用半角逗号隔开
                black_three_id_array_name:[],//三方黑名单数组
                blanceCallNum:-1,// 余额提醒-1:不提醒，大于0则按照数目提醒一级账号提醒的单位是分，二级和三级账号是条数
                phones:"",// 余额提醒目标号码，多个用半角逗号隔开
                isReportPush:0,// http长短信状态推送(即推送长短信状态的次数)0:单条回调 1：多条回调
                push_state_time:0,// http返回状态时间(即推送返回状态时，是否增加的收到状态的时间) 0:不推送；1：推送
                push_snum:0,// http推送计费条数，0:不推送；1：推送。 客户接入方式是http时，此项生效
                speedMax:1000,// cmpp客户，最大发送速度，单位：条/秒
                cmpp_reserved_type:0,// cmpp客户的类型，0:默认； 1:要对内容进行编码，状态返回时，使用Reserved字段返回 cmpp server端使用，目前暂且不使用了
                sms_type:0,// 客户短信类型，0:短信客户；1：语音客户 2：多媒体客户
                fixed_line_onoff:0,// 固话短信开关 0：关闭；1：打开 ,只有当发送类别选择短信时，可显示
                fixed_line_province:0,//  固话短信可发省份，放的是省份id，多个用半角逗号隔开，0：表示全国,只有当发送类别选择短信,并且固话短信开关为 打开 时，可显示
                fixed_line_province_array:["全国"],// 固话短信可发省份，放的是省份id，多个用半角逗号隔开，0：表示全国,,只有当发送类别选择短信,并且固话短信开关为 打开 时，可显示
                own_ext_onoff:0,//自定义扩展码开关，0：关闭；1：打开
                own_ext:"",//自定义扩展码，空字符表示不允许，最长8位数字，如不为空，则在在客户提交的扩展码前面加上该字段的值
                sign_library_id:0,// 签名库id,0:不验证签名库
                sign_ext_onoff:0,// 签名扩展类型 当选择客户签名库后才生效0：使用客户的签名库扩展 1：使用客户的签名库扩展+客户扩展 2：使用客户扩展 (但选择签名库时，显示)
                sign_after:1,// 签名库自加签名(即后报备签名)0：允许；1：不允许 当签名库选择时，生效
                yd_refuse:0,// 移动是否拒发，0：不拒发；1：拒发
                lt_refuse:0,// 联通是否拒发，0：不拒发；1：拒发
                dx_refuse:0,// 电信是否拒发，0：不拒发；1：拒发
                send_pro:"0",// 允许发送的省份id 0:全国； 多个省份id使用半角逗号隔开
                send_pro_array:["全国"],//允许发送的省份的数组
                checknum_onoff:1,// 验证码识别开关 0：关闭；1：打开
                all_template_onoff:1,// 全局模板生效开关0:关闭1:打开
                ischeck:0,// 强制不审核开关，0：关闭；1：打开
                access_code:"",//接入码可为空，下发扩展截取依据，下发时去除的部分，上行加的部分
                user_power:"",//客户权限id，多个使用半角逗号隔开
                user_power_array:[],//客户权限的数组
                warning_id:0,// 预警策略类型，对应预警策略表id，0:不匹配策略；
                change_oper_onoff:1,// 是否支持携号转网0：不支持1：支持
                link_is:1,// 是否允许带链接，0:允许；1:不允许
                link_trust_level:1,// 带链接的信任级别， 0:信任；1:不信任。当允许带链接时生效
                user_repeat_strategy_id:0,// 客户重发策略的id，0：不重发；大于0表示选择的重发策略
                ext_num:8,// 要求扩展码位数，最小值为0，0:表示不管扩展码位数
                mo_onoff:0,// 上行是否要求，0:要求；1:不要求
                special_label:"",//特殊分类标签，空表示无标签，标签使用文字，例如：游戏
                contact_onoff:1,// 是否允许带联系方式0:允许；1:不允许
                pure_english_onoff:1,// 是否允许发送纯英文，0:允许；1:不允许
                english_sign_onoff:0,//英文签名是否可发 0：不可发；1：可发
                profit_min:0.02,// 单条最低利润,单位分
                day_pay_max:0,// 单日赔钱最大值,单位元
                check_type:1,// 审核方式，0:免审；1: 必审；2:按量审核+抽样；3:无模板拒发；4:预报备模板
                send_hhmm_start:800,// 可发时段-开始的时分数，格式HHmm
                send_hhmm_end:2000,// 可发时段-结束的时分数，格式HHmm
                send_hhmm_start_hh:'8',//可发时段-开始的时
                send_hhmm_start_mm:'00',//可发时段-开始的分
                send_hhmm_end_hh:'20',//可发时段-结束的时
                send_hhmm_end_mm:'00',//可发时段-结束的分
                send_timeout_set:0,//超时段处理方式 0:延迟发送、1:直接失败
                specify_codenum_onff:1,// 是否要求指定码号,0:要求；1:不要求
                specify_codenum:"",//指定码号当要求指定码号时生效

                fox_type:0,//灵狐审核类型：-1：不验证；0：极速；1：AI
                sampling_rate:2000,//灵狐抽样比率，万分比

                channel_select_style:"2",// 可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
                channel_select_style_array:["三方"],//可选通道类别数组
                send_type:"",//发送类型，例如“三网行业”
                channel_select_type:1,// 通道选取方式，0:指定通道组；1:自动选通道
                user_channel_ids:"",//通道选取方式是指定通道组时，通道id数组,多个使用半角逗号隔开
                user_channel_id_array:[],//通道选取方式是指定通道组时，通道id数组

                keywords_id_YZM:"",//客户验证码 过滤词库，多个使用半角逗号隔开
                keywords_id_YZM_array:[],
                cpidKeywordGroup:"",//客户过滤词库，多个使用半角逗号隔开
                cpidKeywordGroup_array:[],

                yd_price:"0",//移动价格
                lt_price:"0",//联通价格
                dx_price:"0",//电信价格

            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        console.log("user_add activated");
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 100);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        getData(){
            if(this.form_data.user_type == 1){//后付费
                this.form_data.blanceCallNum = -1;
                this.form_data.phones = "";
                this.form_data.blanceCallNum_onoff = 0;//余额提醒开关，0：关闭；1：打开
            }else{
                if(this.form_data.blanceCallNum == -1){
                    this.form_data.blanceCallNum_onoff = 0;//余额提醒开关，0：关闭；1：打开
                }else{
                    this.form_data.blanceCallNum_onoff = 1;//余额提醒开关，0：关闭；1：打开
                }
            }

            if(this.form_data.fail_rtid == ""){//失败状态返回到的cpid,空不返还
                this.form_data.fail_rtid = "no";
            }else{
                this.form_data.fail_rtid = "yes";
            }

            if(this.form_data.isDayNum = -1){
                this.form_data.isDayNum_onoff = 0;////日限开关 0：关闭，1：打开
            }
            if(this.form_data.own_ext == ""){
                this.form_data.own_ext_onoff = 0;//自定义扩展码开关，0：关闭；1：打开
            }else{
                this.form_data.own_ext_onoff = 1;//自定义扩展码开关，0：关闭；1：打开
            }

            if(this.form_data.user_power == ""){//客户平台权限
                this.form_data.user_power_array = [];
            }else{
                this.form_data.user_power_array = ["自助下行导出"];
            }

            //省份改变调用方法
            this.provinceChange(true, 0);

            //固话短信可发省份改变调用方法
            this.fixedLineProvinceChange(true, 0);
        },

        //获取 http对接类别 列表数据
        getHttpTypeDataList(){
            //请求接口
            API.UserServlet({
                param: "getHttpTypeDataList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.httpTypeList = res.list;
                }
            });
        },

        //获取过滤词库列表数据
        getKewyordGroupList(){
            //请求接口
            API.KeywordsServlet({
                param: "listGroup"
            }).then((res) => {
                if (res.recode === 0) {
                    this.kewyordGroupList = res.list;

                    var yzm_word_array_yes = ["生僻字","赌博"];//验证码过滤词库
                    var word_array_no = ["物业水电催缴","生僻字"];//账号的非这些的过滤词库
                    this.form_data.keywords_id_YZM_array
                    for(var i=0;i<this.kewyordGroupList.length;i++){
                        var item = this.kewyordGroupList[i];
                        var keyword_group_name = item.name;
                        var keyword_group_id = item.id;

                        if(yzm_word_array_yes.indexOf(keyword_group_name) > -1){
                            this.form_data.keywords_id_YZM_array.push(keyword_group_id);
                        }

                        if(word_array_no.indexOf(keyword_group_name) == -1){
                            this.form_data.cpidKeywordGroup_array.push(keyword_group_id);
                        }
                       
                    }
                }
            });
        },
        //获取签名库列表数据
        getSignLibraryList(){
            //请求接口
            API.SignLibraryServlet({
                param: "list"
            }).then((res) => {
                if (res.recode === 0) {
                    this.signLibraryList = res.list;
                    var obj = {sign_library_id: 0, sign_name: "不验签名库" };
                    this.signLibraryList.unshift(obj);
                }
            });
        },

        //获取预警策略数据
        getWarningList(){
            //请求接口
            API.ControlStrategyServlet({
                param: "list"
            }).then((res) => {
                if (res.recode === 0) {
                    this.warningList = res.list;//预警策略数据-请求接口获取
                    var obj = {id: 0, name: "不配置策略" };
                    this.warningList.unshift(obj);
                }
            });
        },
        //获取重发策略数据
        getRepeatStrategyList(){
            //请求接口
            API.UserServlet({
                param: "repeatStrategyList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.repeat_strategyList = res.list;//重发策略数据-请求接口获取
                    var obj = {id: 0, name: "不重发" };
                    this.repeat_strategyList.unshift(obj);
                }
            });
        },
        //获取特殊标签数据
        getSpecialLabelList(){
            //请求接口
            API.LabelServlet({
                param: "specialAllList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.special_labelList = res.list;//特殊标签数据-请求接口获取
                    // var obj = {value: "", name: "无" };
                    // this.special_labelList.unshift(obj);
                }
            });
        },

        //获取三方黑名单列表-不分页
        getBlackThreeList(){//请求接口
            API.UserServlet({
                param: "blackThreeList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.blackThreeList = res.list;
                }
            });
        },

        //三方黑名单列表改变调用方法
        blackThreeChange(event, id){
            // 如果是选中
            if (event) {
                // 把选中的id存入数组
                if(this.form_data.black_three_id_array == ""){
                    this.form_data.black_three_id_array = id+"";
                }else{
                    this.form_data.black_three_id_array += ","+id;
                }
            } else {//未选中
                var black_three_id_arrays = this.form_data.black_three_id_array.split(",");
                var black_three_id_array_new = "";
                for(var i = 0;i<black_three_id_arrays.length;i++){
                    var one = black_three_id_arrays[i];
                    if(one != id+""){
                        if(black_three_id_array_new == ""){
                            black_three_id_array_new = one+"";
                        }else{
                            black_three_id_array_new += ","+one;
                        }
                    }
                }
                this.form_data.black_three_id_array = black_three_id_array_new;
            }  
        },

        //获取省份列表数据
        getProvList(){
            //请求接口
            API.ProvinceServlet({
                param: "getProv"
            }).then((res) => {
                if (res.recode === 0) {
                    this.provList = res.provinceList;//省份列表数据-请求接口获取
                    var obj = {id: 0, province: "全国" };
                    this.provList.unshift(obj);
                }
            });
        },
        //省份改变调用方法
        provinceChange(event, id){
            // console.log("省份改变调用方法:id="+id);
            // 如果是选中
            if (event) {
                // 把选中的id存入数组
                if(id == 0){
                    this.form_data.send_pro = id+"";
                    this.form_data.send_pro_array = [];
                    this.form_data.send_pro_array.push("全国");

                    this.prov_city_select_map.clear();//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                }else{
                    if(this.form_data.send_pro == ""){
                        this.form_data.send_pro = id+"";
                    }else{
                        if(this.form_data.send_pro == "0"){
                            this.form_data.send_pro = id+"";

                            var index = this.form_data.send_pro_array.indexOf("全国"); 
                            if (index > -1) { 
                                this.form_data.send_pro_array.splice(index, 1); 
                            } 
                        }else{
                            this.form_data.send_pro += ","+id;
                        }
                    }
                }
            } else {//未选中
                var send_pros = this.form_data.send_pro.split(",");
                var send_pro_new = "";
                for(var i = 0;i<send_pros.length;i++){
                    var one = send_pros[i];
                    if(one != id+""){
                        if(send_pro_new == ""){
                            send_pro_new = one+"";
                        }else{
                            send_pro_new += ","+one;
                        }
                    }
                }
                this.form_data.send_pro = send_pro_new;
            }
            // console.log("this.form_data.send_pro:"+this.form_data.send_pro);
        },

        //选择城市
        selectCity(prov_id,prov_name){
            console.log("选择城市方法:prov_id="+prov_id+",prov_name="+prov_name);
            if(prov_id == 0 || prov_id == 1 || prov_id == 4 || prov_id == 7 || prov_id == 15){//1：上海 4：北京 7:天津 15：未知
                return;
            }
            var prov_city_obj = this.prov_city_select_map.get(prov_id+'');
            if(prov_city_obj != null){
                this.prop_prov_city_select_item.citys = prov_city_obj.citys; // 示例：['保定','石家庄']
            }else{
                this.prop_prov_city_select_item.citys = [];
            }

            //省市选择-弹出层
            this.prop_prov_city_select_item.prov_id = prov_id;
            this.prop_prov_city_select_item.prov_name = prov_name;
            this.prop_prov_city_select_i ++;//弹出框是否可见
        },
        provCitySelectResult(prov_id,prov_name,citys){
            console.log("选择城市结果方法prov_id="+prov_id+",prov_name="+prov_name+",citys:",citys);
            var cityObj = {prov_name:prov_name,citys:citys};
            if(citys.length == 0){
                this.prov_city_select_map.delete(prov_id+'');
            }else{
                this.prov_city_select_map.set(prov_id+'',cityObj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
            }

            if(this.form_data.send_pro == "0"){//选择的是全国的话,把值置空
                this.form_data.send_pro = "";
                this.form_data.send_pro_array = [];
            }else {
                var send_pros = this.form_data.send_pro.split(",");

                var send_pro_new = "";
                for(var i = 0;i<send_pros.length;i++){
                    var one = send_pros[i];
                    if(one != prov_id+""){
                        if(send_pro_new == ""){
                            send_pro_new = one+"";
                        }else{
                            send_pro_new += ","+one;
                        }
                    }
                }
                this.form_data.send_pro = send_pro_new;

            }
            console.log("(2)this.form_data.send_pro:"+this.form_data.send_pro);

            // console.log("是否包含：",this.prov_city_select_map.get(prov_id));
           //console.log("是否包含：",this.prov_city_select_map.get(prov_id));

            this.$forceUpdate();//重新渲染vue
            

            // this.prov_city_select_map.forEach((value, key) => {
            //     console.log( "key:"+key," value:",value)
            // })
        },
        
        //固话短信可发省份改变调用方法
        fixedLineProvinceChange(event, id){
            // 如果是选中
            if (event) {
                // 把选中的id存入数组
                if(id == 0){
                    this.form_data.fixed_line_province = id+"";
                    this.form_data.fixed_line_province_array = [];
                    this.form_data.fixed_line_province_array.push("全国");
                }else{
                    if(this.form_data.fixed_line_province == ""){
                        this.form_data.fixed_line_province = id+"";
                    }else{
                        if(this.form_data.fixed_line_province == "0"){
                            this.form_data.fixed_line_province = id+"";

                            var index = this.form_data.fixed_line_province_array.indexOf("全国"); 
                            if (index > -1) { 
                                this.form_data.fixed_line_province_array.splice(index, 1); 
                            } 
                        }else{
                            this.form_data.fixed_line_province += ","+id;
                        }
                    }
                }
            } else {//未选中
                var fixed_line_provinces = this.form_data.fixed_line_province.split(",");
                var fixed_line_province_new = "";
                for(var i = 0;i<fixed_line_provinces.length;i++){
                    var one = fixed_line_provinces[i];
                    if(one != id+""){
                        if(fixed_line_province_new == ""){
                            fixed_line_province_new = one+"";
                        }else{
                            fixed_line_province_new += ","+one;
                        }
                    }
                }
                this.form_data.fixed_line_province = fixed_line_province_new;
            }
        },

        //获取通道列表数据-指定通道组使用的
        getChanenlList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },

        //获取公司列表数据
        getCompanyList(){
            //请求接口
            API.CompanyServlet({
                param: "selList",
                searchID: "",
            }).then((res) => {
                if (res.recode === 0) {
                    this.companyList = res.list;//公司列表数据-请求接口获取
                    var obj = {value:0,name:"请选择"};
                    this.companyList.unshift(obj);
                }
            });
        },



        //提交
        onSubmit(){
            console.log("提交");

            if(this.form_data.sms_type == 0){// 客户短信类型，0:短信客户；1：语音客户 2：多媒体客户
                if(this.form_data.fixed_line_onoff == 0){//固话短信开关 0：关闭；1：打开 ,只有当发送类别选择短信时，可显示
                    this.form_data.fixed_line_province = "0";
                }
            }else{
                this.form_data.fixed_line_onoff = 0;
                this.form_data.fixed_line_province = "0";
            }

            var submit_obj = {};
            submit_obj.cpid = this.form_data.cpid;
            submit_obj.company_id = this.form_data.company_id;
            submit_obj.param="add";

            submit_obj.cppwd = this.form_data.cppwd;//用户接口密码
            submit_obj.login_pwd = this.form_data.login_pwd;//用户登录密码
            submit_obj.secret = this.form_data.secret;//私钥  选填，不填表示使用系统默认值

            submit_obj.relationphone = this.form_data.relationphone;//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
            submit_obj.user_type = this.form_data.user_type;//用户付费类型0:预付费类型1：后付费类型
            submit_obj.fail_rtid = this.form_data.fail_rtid;//失败状态返回到的cpid,空不返还
            submit_obj.ip = this.form_data.ip;//客户鉴权ip地址(多个用半角逗号隔开)
            submit_obj.interface_type = this.form_data.interface_type;//接入方式，0:CMPP；1:HTTP
            submit_obj.qxt_http_type = this.form_data.qxt_http_type;////http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
            submit_obj.url = this.form_data.url;//回调地址
            submit_obj.mourl = this.form_data.mourl;//上行地址（即上行是回调用）
            submit_obj.blanceCallNum = this.form_data.blanceCallNum;//余额提醒  -1:不提醒，大于0则按照数目提醒  一级账号提醒的单位是分，二级和三级账号是条数
            submit_obj.phones = this.form_data.phones;//余额提醒目标号码，多个用半角逗号隔开
            submit_obj.remark = this.form_data.remark;//备注 ，在用户统计里显示

            submit_obj.unknownReturn = this.form_data.unknownReturn;// 未知返还比例0：不返还；大于0小于等于100则按比例返还（新）
            submit_obj.yld_level = this.form_data.yld_level;// 客户类型 ，默认为4，1：验证码； 2：行业通知；3：营销；4：四类
            submit_obj.hy_send_yx_onoff = this.form_data.hy_send_yx_onoff;//行业发营销监控开关  0:开；1:关
            submit_obj.hy_send_yx_profit_money = this.form_data.hy_send_yx_profit_money;//行业发营销监控打开时，最低利润，单位分
            submit_obj.isT = this.form_data.isT;// 回T退订0：关闭；1：打开
            submit_obj.isDayNum = this.form_data.isDayNum;// 日限次数-1：表示不限
            submit_obj.isminutetime = this.form_data.isminutetime;// 号码限流，分钟单位，0:不限制 
            submit_obj.isminutenum = this.form_data.isminutenum;// 号码限流，分钟限制条数
            submit_obj.sign_position = this.form_data.sign_position;// 签名位置：0：强制前置；1：强制后置；2：不管签名位置
            submit_obj.sign_add = this.form_data.sign_add;//自动加签名，在下发内容上自动添加的签名，如为空表示不加
            submit_obj.isDoublesign = this.form_data.isDoublesign;// 双签名修改开关，0:关闭；1:打开
            submit_obj.is_top_end_sign = this.form_data.is_top_end_sign;//头尾双签名拒发开关，0:关闭；1:打开
            submit_obj.yzmKeyword = this.form_data.yzmKeyword;// 验证码过滤字 0：不验证；1：包含转人工；2：包含拦截
            submit_obj.isKeyword = this.form_data.isKeyword;// 过滤字0：不验证；1：包含转人工；2：包含拦截
            submit_obj.selfBlack = this.form_data.selfBlack;// 自有黑名单0：关闭；1：打开
            submit_obj.operBlack = this.form_data.operBlack;// 运营商黑名单0：关闭；1：打开
            submit_obj.backBlack = this.form_data.backBlack;// 回T退订黑名单0：关闭；1：打开
            submit_obj.black_three_id_array = this.form_data.black_three_id_array;//三方黑名单供应商ID，多个使用半角逗号隔开
            submit_obj.blanceCallNum = this.form_data.blanceCallNum;// 余额提醒-1:不提醒，大于0则按照数目提醒一级账号提醒的单位是分，二级和三级账号是条数
            submit_obj.phones = this.form_data.phones;// 余额提醒目标号码，多个用半角逗号隔开
            submit_obj.isReportPush = this.form_data.isReportPush;// http长短信状态推送(即推送长短信状态的次数)0:单条回调 1：多条回调
            submit_obj.push_state_time = this.form_data.push_state_time;// http返回状态时间(即推送返回状态时，是否增加的收到状态的时间) 0:不推送；1：推送
            submit_obj.push_snum = this.form_data.push_snum;// http推送计费条数，0:不推送；1：推送。 客户接入方式是http时，此项生效
            submit_obj.speedMax = this.form_data.speedMax;// cmpp客户，最大发送速度，单位：条/秒
            submit_obj.cmpp_reserved_type = this.form_data.cmpp_reserved_type;// cmpp客户的类型，0:默认； 1:要对内容进行编码，状态返回时，使用Reserved字段返回 cmpp server端使用，目前暂且不使用了
            submit_obj.sms_type = this.form_data.sms_type;// 客户短信类型，0:短信客户；1：语音客户 2：多媒体客户
            submit_obj.fixed_line_onoff = this.form_data.fixed_line_onoff;//固话短信开关 0：关闭；1：打开 ,只有当发送类别选择短信时，可显示
            submit_obj.fixed_line_province = this.form_data.fixed_line_province;//固话短信可发省份，放的是省份id，多个用半角逗号隔开，0：表示全国,只有当发送类别选择短信,并且固话短信开关为 打开 时，可显示
            submit_obj.own_ext = this.form_data.own_ext;//自定义扩展码，空字符表示不允许，最长8位数字，如不为空，则在在客户提交的扩展码前面加上该字段的值
            submit_obj.sign_library_id = this.form_data.sign_library_id;// 签名库id,0:不验证签名库
            submit_obj.sign_ext_onoff = this.form_data.sign_ext_onoff;// 签名扩展类型 当选择客户签名库后才生效0：使用客户的签名库扩展 1：使用客户的签名库扩展+客户扩展 2：使用客户扩展 (但选择签名库时，显示)
            submit_obj.sign_after = this.form_data.sign_after;// 签名库自加签名(即后报备签名)0：允许；1：不允许 当签名库选择时，生效
            submit_obj.yd_refuse = this.form_data.yd_refuse;// 移动是否拒发，0：不拒发；1：拒发
            submit_obj.lt_refuse = this.form_data.lt_refuse;// 联通是否拒发，0：不拒发；1：拒发
            submit_obj.dx_refuse = this.form_data.dx_refuse;// 电信是否拒发，0：不拒发；1：拒发
            submit_obj.send_pro = this.form_data.send_pro;// 允许发送的省份id0:全国； 多个省份id使用半角逗号隔开
            submit_obj.checknum_onoff = this.form_data.checknum_onoff;// 验证码识别开关 0：关闭；1：打开
            submit_obj.all_template_onoff = this.form_data.all_template_onoff;// 全局模板生效开关0:关闭1:打开
            submit_obj.ischeck = this.form_data.ischeck;// 强制不审核开关，0：关闭；1：打开
            submit_obj.access_code = this.form_data.access_code;//接入码可为空，下发扩展截取依据，下发时去除的部分，上行加的部分
            submit_obj.user_power = this.form_data.user_power;//客户权限id，多个使用半角逗号隔开
            submit_obj.warning_id = this.form_data.warning_id;// 预警策略类型，对应预警策略表id，0:不匹配策略；
            submit_obj.change_oper_onoff = this.form_data.change_oper_onoff;// 是否支持携号转网0：不支持1：支持
            submit_obj.link_is = this.form_data.link_is;// 是否允许带链接，0:允许；1:不允许
            submit_obj.link_trust_level = this.form_data.link_trust_level;// 带链接的信任级别， 0:信任；1:不信任。当允许带链接时生效
            submit_obj.user_repeat_strategy_id = this.form_data.user_repeat_strategy_id;// 客户重发策略的id，0：不重发；大于0表示选择的重发策略
            submit_obj.ext_num = this.form_data.ext_num;// 要求扩展码位数，最小值为0，0:表示不管扩展码位数
            submit_obj.mo_onoff = this.form_data.mo_onoff;// 上行是否要求，0:要求；1:不要求
            submit_obj.special_label = this.form_data.special_label;//特殊分类标签，空表示无标签，标签使用文字，例如：游戏
            submit_obj.contact_onoff = this.form_data.contact_onoff;// 是否允许带联系方式0:允许；1:不允许
            submit_obj.pure_english_onoff = this.form_data.pure_english_onoff;// 是否允许发送纯英文，0:允许；1:不允许
            submit_obj.english_sign_onoff = this.form_data.english_sign_onoff;// 英文签名是否可发 0：不可发；1：可发
            submit_obj.profit_min = this.form_data.profit_min;// 单条最低利润,单位分
            submit_obj.day_pay_max = this.form_data.day_pay_max;// 单日赔钱最大值,单位元
            submit_obj.check_type = this.form_data.check_type;// 审核方式，0:免审；1: 必审；2:按量审核+抽样；3:无模板拒发；4:预报备模板
            submit_obj.send_hhmm_start = this.form_data.send_hhmm_start;// 可发时段-开始的时分数，格式HHmm
            submit_obj.send_hhmm_end = this.form_data.send_hhmm_end;//可发时段-结束的时分数，格式HHmm
            submit_obj.send_timeout_set = this.form_data.send_timeout_set;//超时段处理方式 0:延迟发送、1:直接失败
            submit_obj.specify_codenum_onff = this.form_data.specify_codenum_onff;// 是否要求指定码号,0:要求；1:不要求
            submit_obj.specify_codenum = this.form_data.specify_codenum;//指定码号当要求指定码号时生效
            submit_obj.fox_type = this.form_data.fox_type;//灵狐审核类型：-1：不验证；0：极速；1：AI
            submit_obj.sampling_rate = this.form_data.sampling_rate;//灵狐抽样比率，万分比
            submit_obj.channel_select_style = this.form_data.channel_select_style;//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
            submit_obj.send_type = this.form_data.send_type;//发送类型，例如“三网行业”
            submit_obj.channel_select_type = this.form_data.channel_select_type;//通道选取方式，0:指定通道组；1:自动选通道
            submit_obj.user_channel_ids = this.form_data.user_channel_ids;//通道选取方式是指定通道组时，通道id数组,多个使用半角逗号隔开
            submit_obj.keywords_id_YZM = this.form_data.keywords_id_YZM;//客户验证码 过滤词库，多个使用半角逗号隔开
            submit_obj.cpidKeywordGroup = this.form_data.cpidKeywordGroup;//客户过滤词库，多个使用半角逗号隔开

            submit_obj.yd_price = this.form_data.yd_price;//移动价格
            submit_obj.lt_price = this.form_data.lt_price;//联通价格
            submit_obj.dx_price = this.form_data.dx_price;//信价格

            var send_citys = [];
            this.prov_city_select_map.forEach((value, key) => {//key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                console.log(key,value)
                var prov_id = key;
                var citys = value.citys;
                var city_array = [];
                for(var i =0;i<citys.length;i++){
                    var city_obj = {city:citys[i]};
                    city_array.push(city_obj);
                }

                var obj = {prov_id:prov_id,city_array:city_array};
                send_citys.push(obj);
            });
            submit_obj.send_citys = JSON.stringify(send_citys);


            if(API.isEmtry(submit_obj.cpid)){
                this.$message.error("请填写账号");
                return;
            }
            if(API.isEmtry(submit_obj.company_id) || submit_obj.company_id<=0){
                this.$message.error("请选择客户所属的公司");
                return;
            }
            if(API.isEmtry(submit_obj.cppwd)){
                this.$message.error("请填写接口密码");
                return;
            }
            if(API.isEmtry(submit_obj.login_pwd)){
                this.$message.error("请填写登录密码");
                return;
            }

            if(submit_obj.interface_type == 1){//接入方式，0:CMPP；1:HTTP
                if(API.isEmtry(submit_obj.qxt_http_type)){
                    this.$message.error("请选择http对接协议");
                    return;
                }
            }else{
                if(submit_obj.interface_type == 0){//接入方式，0:CMPP；1:HTTP
                    submit_obj.qxt_http_type = "";//cmpp协议时，置空
                }
            }

            if(submit_obj.user_type == 1){//后付费
                submit_obj.blanceCallNum = -1;
                submit_obj.phones = "";
            }else{
                //console.log("submit_obj.blanceCallNum:"+submit_obj.blanceCallNum+"|"+String.valueOf(submit_obj.blanceCallNum));
                if(API.isEmtry(submit_obj.blanceCallNum)){
                     this.$message.error("请填写余额提醒阈值");
                     return;
                }else{
                    if(this.form_data.blanceCallNum_onoff == 0){//blanceCallNum_onoff:0,//余额提醒开关，0：关闭；1：打开){
                        submit_obj.blanceCallNum = -1;
                        submit_obj.phones = "";
                    }else{
                        if(API.isEmtry(submit_obj.phones)){
                            this.$message.error("请填写余额提醒目标号码");
                            return;
                        }
                    }
                }
            }

            if(submit_obj.fail_rtid == "no"){//失败状态返回到的cpid,空不返还
                submit_obj.fail_rtid = "";
            }else{
                submit_obj.fail_rtid = submit_obj.cpid;
            }

            if(this.form_data.own_ext_onoff == 1){//自定义扩展码开关，0：关闭；1：打开
                if(API.isEmtry(this.form_data.own_ext)){
                    this.$message.error("请填写自定义扩展码");
                    return;
                }
            }

            if(this.form_data.isminutetime_ononff == 1){
                if(API.isEmtry(this.form_data.isminutenum)){
                    this.$message.error("请填写号码限流的条数");
                    return;
                }
            }
            if(this.form_data.isDayNum_onoff == 1){//日限开关 0：不限，1：限制
                if(API.isEmtry(this.form_data.isDayNum) ){
                    this.$message.error("请填写日限的条数");
                    return;
                }
            }else{
                submit_obj.isDayNum = -1;
            }


            if(API.isEmtry(this.form_data.profit_min)){
                this.$message.error("请填写单条最低利润");
                return;
            }
            //console.log("this.form_data.day_pay_max:"+this.form_data.day_pay_max+" u:"+API.isEmtry(this.form_data.day_pay_max)+";p:"+typeof(this.form_data.day_pay_max));
            if(API.isEmtry(this.form_data.day_pay_max)){
                this.$message.error("请填写单日赔钱最大值");
                return;
            }
            if(API.isEmtry(this.form_data.send_hhmm_start_hh) || API.isEmtry(this.form_data.send_hhmm_end_hh)){
                this.$message.error("请填写可发送时段");
                return;
            }else{
                if(this.form_data.send_hhmm_start_hh>=25 || this.form_data.send_hhmm_end_hh>=25){
                    this.$message.error("可发送时段不能超过24小时");
                    return;
                }else{
                    submit_obj.send_hhmm_start = parseInt(this.form_data.send_hhmm_start_hh+""+this.form_data.send_hhmm_start_mm);
                    submit_obj.send_hhmm_end = parseInt(this.form_data.send_hhmm_end_hh+""+this.form_data.send_hhmm_end_mm);
                }
            }

            if(this.form_data.user_power_array.length == 0){
                submit_obj.user_power = "";
            }else{
                submit_obj.user_power = "0";//客户权限
            }


            if(submit_obj.fail_rtid == "no"){//失败状态返回到的cpid,空不返还
                submit_obj.fail_rtid = "";
            }else{
                submit_obj.fail_rtid = submit_obj.cpid;
            }

            if(this.form_data.fox_type == 0 || this.form_data.fox_type == 1){//灵狐审核类型：-1：不验证；0：极速；1：AI
                if(this.form_data.sampling_rate == ""){
                    this.$message.error("请填写灵狐抽样比率");
                    return;
                }else{
                    if(this.form_data.sampling_rate > 10000){
                        this.$message.error("灵狐抽样比率数值不能超过1万");
                        return;
                    }
                }
            }

            
            if(this.form_data.channel_select_style_array.length == 0){
                this.$message.error("请选择可选通道类别");
                return;
            }else{
                submit_obj.channel_select_style = "";
                for(var i=0;i<this.form_data.channel_select_style_array.length;i++){
                    var v = this.form_data.channel_select_style_array[i];
                    if(submit_obj.channel_select_style != ""){
                        submit_obj.channel_select_style += ",";
                    }
                    if(v == '直连'){
                        submit_obj.channel_select_style += "0";
                    }else if(v == '准直连'){
                        submit_obj.channel_select_style += "1";
                    }else if(v == '三方'){
                        submit_obj.channel_select_style += "2";
                    }else if(v == '厂商'){
                        submit_obj.channel_select_style += "3";
                    }
                }
                // console.log("submit_obj.channel_select_style:"+submit_obj.channel_select_style);
            }

            if(this.form_data.channel_select_type == 0){
                if(this.form_data.user_channel_id_array.length == 0){
                    this.$message.error("请选择指定通道组");
                    return;
                }else{
                     submit_obj.user_channel_ids = this.form_data.user_channel_id_array.toString();
                }
                
            }else{
                submit_obj.user_channel_ids = "";
            }

            if(this.form_data.yzmKeyword != 0){
                if(this.form_data.keywords_id_YZM_array.length == 0){
                    this.$message.error("请选择验证码过滤词库");
                    return;
                }else{
                    submit_obj.keywords_id_YZM = this.form_data.keywords_id_YZM_array.toString();
                }
            }else{
                submit_obj.keywords_id_YZM = "";
            }
            if(this.form_data.isKeyword != 0){
                if(this.form_data.cpidKeywordGroup_array.length == 0){
                    this.$message.error("请选择账号过滤词库");
                    return;
                }else{
                    submit_obj.cpidKeywordGroup = this.form_data.cpidKeywordGroup_array.toString();
                }
            }else{
                submit_obj.cpidKeywordGroup = "";
            }

            API.UserServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}

/* element 单选框样式调整*/
.cur_dialog >>>.el-radio-group {
    line-height: 30px;
    vertical-align: bottom;
}

</style>