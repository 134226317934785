<template>
    <div id="app_user_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    添加
                </el-button>
                <el-button type="primary" @click="prop_user_add_upd_i ++" size="mini">
                    简易变更
                </el-button>
            </div>
            
            <div class="head_top_title">客户管理</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row type="flex" align="middle"  style="padding:10px 0;">
                <el-input  v-model="searchID" @change="submit_cha_xun()" placeholder="请输入cpid或公司名称" prefix-icon="el-icon-search" size="small"></el-input>
                <el-button type="primary"  plain size="mini" style="margin-left:20px;" @click="submit_cha_xun()">查询</el-button>
            </el-row>

            <el-divider content-position="left">所有客户：{{tableData_total}}个</el-divider>

            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">
                <template v-for="item in tableData">
                    <el-row type="flex" align="middle" @click.native="detail(item.cpid)" style="border-bottom:1px solid #DCDFE6;padding:5px 0px;">
                        <el-col :span="15">
                            <!-- 客户cpid+公司名 -->
                            <div style="line-height:25px">
                                {{item.cpid}}<br>
                                <span style="color:gray;">{{item.company_name}}</span>
                            </div>
                        </el-col>
                        <el-col :span="9">
                            <el-row type="flex" justify="end" align="middle" style="margin-right:10px;">
                                <div v-if="item.child.length>0" class="font_size6" 
                                    style="background-color: #009688;color:#fff;margin-right:10px;padding:2px;border-radius: 2px;">
                                    {{item.child.length}}个子账户
                                </div>
                                <i class="el-icon-arrow-right" style="font-size:20px;"></i>
                            </el-row>
                            
                        </el-col>
                    </el-row>

                </template>
            </el-row>

        </el-row>

        <!-- 客户增加组件 -->
        <UserAdd :prop_change_i="prop_user_add_i"
            @result="userAddResult"
        ></UserAdd>

        <!-- 客户简易增加组件 -->
        <UserAddUpd :prop_change_i="prop_user_add_upd_i"
        ></UserAddUpd>
        
    </div>
</template>
<script>
import router from '../../router/route.js'
import API from '../../api/api';
import UserAdd from './user_add.vue';//组件 客户增加
import UserAddUpd from './user_add_upd.vue';//组件 客户增加

export default {

    components: {
       UserAdd,
       UserAddUpd
    },
    
    data(){
        return{
            isCollapse:true,

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            searchID:"",//搜索条件
            tableData: [],//列表数据
            tableData_total:0,//表格数据总量

            //客户增加
            prop_user_add_i:0,//弹出框是否可见

            //客户简易增加
            prop_user_add_upd_i:0,//弹出框是否可见
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    activated() {
        this.searchID = this.$options.data().searchID;//单个恢复初始化
        this.tableData = this.$options.data().tableData;//单个恢复初始化
        this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 185);
        },

        //输入值发送变化时请求接口
        submit_cha_xun() {
            //请求接口
            API.UserServlet({
                param: "listSelectAll",
                searchID:this.searchID,
            }).then((res) => {
                // this.loading = false;
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.size;
                } else {
                }
                
            });
        },
        //客户详情
        detail(cpid){
            console.log("跳转到客户详情");
            API.router_to("/user_detail/"+cpid);
        },
        //客户增加
        add(){
            this.prop_user_add_i ++;////弹出框是否可见
        },
        userAddResult(){

        },

        //返回
        goBack() {
            router.push("/user_index");
        }
    }
};

</script>

<style scoped>
/* #app_user_list >>>.el-divider--horizontal{
    margin: 4px 0;
} */
</style>