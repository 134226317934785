<template>
<div>
    <!-- 客户简易变更 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">

                <el-form  :model="form_data" label-width="140px" style="margin-bottom: 12px;">
                    <el-form-item label=" ">
                        <el-radio-group v-model="form_data.user_opt_type">
                            <el-radio-button :label="0">新增</el-radio-button>
                            <el-radio-button :label="1">修改</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    
                    <el-form-item label="账号">
                        <el-input v-model="form_data.cpid" placeholder="请输入账号" size="mini"></el-input>
                    </el-form-item>
                    

                    <el-form-item label="公司" v-show="form_data.user_opt_type == 0">
                        <el-select v-model="form_data.company_id" filterable clearable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in companyList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="登录密码" v-show="form_data.user_opt_type == 0">
                        <el-input v-model="form_data.login_pwd" placeholder="请输入登录密码" size="mini"></el-input>
                    </el-form-item>

                    

                    <el-form-item label="联系方式" v-show="form_data.user_opt_type == 0">
                        <el-input v-model="form_data.relationphone" placeholder="选填,多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="付费类型" v-show="form_data.user_opt_type == 0">
                        <el-radio-group v-model="form_data.user_type" size="mini" >
                            <el-radio :label="0">预付费</el-radio>
                            <el-radio :label="1">后付费</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <!-- <el-form-item label="客户鉴权IP" v-show="form_data.user_opt_type == 0">
                        <el-input v-model="form_data.ip" placeholder="多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item> -->

                    <el-form-item label="客户接入方式" v-show="form_data.user_opt_type == 0">
                        <el-radio-group v-model="form_data.interface_type" size="mini" >
                            <el-radio :label="0">CMPP</el-radio>
                            <el-radio :label="1">HTTP</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="http对接协议" v-show="form_data.interface_type == 1 && form_data.user_opt_type == 0">
                        <el-select v-model="form_data.qxt_http_type" filterable clearable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in httpTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="回调地址" v-show="form_data.interface_type == 1 && form_data.user_opt_type == 0">
                        <el-input v-model="form_data.url" placeholder="请填写回调地址" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="上行地址" v-show="form_data.interface_type == 1 && form_data.user_opt_type == 0">
                        <el-input v-model="form_data.mourl" placeholder="请填写上行地址" size="mini"></el-input>
                    </el-form-item>


                    <el-form-item label="客户类型">
                        <el-radio-group v-model="form_data.yld_level" size="mini" >
                            <el-radio :label="1">验证码</el-radio>
                            <el-radio :label="2">行业</el-radio>
                            <el-radio :label="3">营销</el-radio>
                            <el-radio :label="4">四类</el-radio>
                        </el-radio-group>
                    </el-form-item>


                    <el-form-item label="发送类别"  v-show="form_data.user_opt_type == 0">
                        <el-radio-group v-model="form_data.sms_type" size="mini">
                            <el-radio :label="0">短信</el-radio>
                            <el-radio :label="1">语音</el-radio>
                            <el-radio :label="2">多媒体短信</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="可发运营商">
                        <el-checkbox-group v-model="form_data.oper_array" size="mini">
                            <el-checkbox  label="0">移动</el-checkbox>
                            <el-checkbox  label="1">联通</el-checkbox>
                            <el-checkbox  label="2">电信</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="可发省份" >
                        <el-checkbox-group v-model="form_data.send_pro_array" size="mini">
                            <template v-for="item in provList" >
                                <el-checkbox :key="item.id" :label="item.province" :disabled="prov_city_select_map.has(item.id+'')" :indeterminate="prov_city_select_map.has(item.id+'')" @change="provinceChange($event, item.id)" style="margin-right: 0px;"><br></el-checkbox>
                                
                                <div :key="item.id+1000" class="cursor" style="font-size:14px;display: inline-block;position: relative;margin-right: 20px;" @click="selectCity(item.id,item.province)">{{item.province}}</div>
                            </template>
                            <!-- <el-checkbox v-for="item in provList" :label="item.province" :key="item.id" @change="provinceChange($event, item.id)"> </el-checkbox> -->
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="可发省份下的地市" v-show="prov_city_select_map.size >  0">
                         <template v-for="(value, key) in prov_city_select_map" >
                            <div :key="key">{{value[1].prov_name+"："+value[1].citys}}</div>
                        </template>
                    </el-form-item>

                    <el-form-item label="移动价格" v-show="form_data.oper_array.indexOf('0') > -1">
                        <el-input v-model="form_data.yd_price" placeholder="请填写移动价格" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="联通价格" v-show="form_data.oper_array.indexOf('1') > -1">
                        <el-input v-model="form_data.lt_price" placeholder="请填写联通价格" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="电信价格" v-show="form_data.oper_array.indexOf('2') > -1">
                        <el-input v-model="form_data.dx_price" placeholder="请填写联通价格" size="mini" style="vertical-align: middle;"
                            oninput="value=value.replace(/[^(-?\d+)(\.\d+)?$]/g,'')">
                            <template slot="append">分</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="发送类型">
                        <el-input v-model="form_data.send_type" placeholder="选填,例如：三网行业" size="mini">
                        </el-input>
                    </el-form-item>  
                                    

                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>


            </el-row>

        </el-row>


    </el-dialog>


    <!--省市选择 组件 -->
    <ProvCitySelect :prop_change_i="prop_prov_city_select_i"
        :prop_item="prop_prov_city_select_item"
        @result="provCitySelectResult"
    ></ProvCitySelect>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API,{el_select_iphone_bug} from '../../api/api';
import ProvCitySelect from '../../components/prov_city/prov_city_select.vue';//客户百分比通道组组件

//import axios from "axios";
export default {
    components: {
        ProvCitySelect
    },

    props:{
        prop_change_i:0,
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                //初始化设置
                this.initSetUp();

                this.cur_title = "客户简易增加";
                setTimeout(() => {

                    //获取 http对接类别 列表数据
                    this.getHttpTypeDataList();

                    //获取省份列表数据
                    this.getProvList();

                    //获取公司列表数据
                    this.getCompanyList();

                    //查询
                    this.getData();

                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            tableData_titil:[],//客户cpid+公司名
            provList:[],//省份列表数据-请求接口获取
            companyList:[],//公司列表数据-请求接口获取
            httpTypeList:[],//http对接类别 列表数据  -请求接口获取

             //省市选择-弹出层
            prop_prov_city_select_i:0,//弹出框是否可见
            prop_prov_city_select_item:{},

            prov_city_select_map : new Map(),//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}

            form_data:{
                cpid:"",
                user_opt_type:0,//账号操作类别,0：新增；1：修改
                company_id:0,//公司id
                
                login_pwd:"",//用户登录密码
                relationphone:"",//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
                user_type:1,//用户付费类型0:预付费类型1：后付费类型
                // ip:"",//客户鉴权ip地址(多个用半角逗号隔开)
                interface_type:0,//接入方式，0:CMPP；1:HTTP
                qxt_http_type:"system",//http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
                url:"",//回调地址
                mourl:"",//上行地址（即上行是回调用）
               
                
                yld_level:-1,// 客户类型 ，默认为4，1：验证码； 2：行业通知；3：营销；4：四类
                
                
                sms_type:0,// 客户短信类型，0:短信客户；1：语音客户 2：多媒体客户
                oper:"",//可发运营商,0:移动;1联通,2电信（多个用半角逗号隔开）
                oper_array:[],//可发运营商数组
               
                send_pro:"0",// 允许发送的省份id0:全国； 多个省份id使用半角逗号隔开
                send_pro_array:["全国"],//允许发送的省份的数组
                
                send_type:"",//发送类型，例如“三网行业”
                
               
                yd_price:"0",//移动价格
                lt_price:"0",//联通价格
                dx_price:"0",//电信价格

            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 60);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        getData(){

            //省份改变调用方法
            this.provinceChange(true, 0);
        },

        //获取 http对接类别 列表数据
        getHttpTypeDataList(){
            //请求接口
            API.UserServlet({
                param: "getHttpTypeDataList"
            }).then((res) => {
                if (res.recode === 0) {
                    this.httpTypeList = res.list;
                }
            });
        },
       
        //获取省份列表数据
        getProvList(){
            //请求接口
            API.ProvinceServlet({
                param: "getProv"
            }).then((res) => {
                if (res.recode === 0) {
                    this.provList = res.provinceList;//省份列表数据-请求接口获取
                    var obj = {id: 0, province: "全国" };
                    this.provList.unshift(obj);
                }
            });
        },
        //省份改变调用方法
        provinceChange(event, id){
            // console.log("省份改变调用方法:id="+id);
            // 如果是选中
            if (event) {
                // 把选中的id存入数组
                if(id == 0){
                    this.form_data.send_pro = id+"";
                    this.form_data.send_pro_array = [];
                    this.form_data.send_pro_array.push("全国");

                    this.prov_city_select_map.clear();//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                }else{
                    if(this.form_data.send_pro == ""){
                        this.form_data.send_pro = id+"";
                    }else{
                        if(this.form_data.send_pro == "0"){
                            this.form_data.send_pro = id+"";

                            var index = this.form_data.send_pro_array.indexOf("全国"); 
                            if (index > -1) { 
                                this.form_data.send_pro_array.splice(index, 1); 
                            } 
                        }else{
                            this.form_data.send_pro += ","+id;
                        }
                    }
                }
            } else {//未选中
                var send_pros = this.form_data.send_pro.split(",");
                var send_pro_new = "";
                for(var i = 0;i<send_pros.length;i++){
                    var one = send_pros[i];
                    if(one != id+""){
                        if(send_pro_new == ""){
                            send_pro_new = one+"";
                        }else{
                            send_pro_new += ","+one;
                        }
                    }
                }
                this.form_data.send_pro = send_pro_new;
            }
            // console.log("this.form_data.send_pro:"+this.form_data.send_pro);
        },

        //选择城市
        selectCity(prov_id,prov_name){
            console.log("选择城市方法:prov_id="+prov_id+",prov_name="+prov_name);
            if(prov_id == 0 || prov_id == 1 || prov_id == 4 || prov_id == 7 || prov_id == 15){//1：上海 4：北京 7:天津 15：未知
                return;
            }
            var prov_city_obj = this.prov_city_select_map.get(prov_id+'');
            if(prov_city_obj != null){
                this.prop_prov_city_select_item.citys = prov_city_obj.citys; // 示例：['保定','石家庄']
            }else{
                this.prop_prov_city_select_item.citys = [];
            }

            //省市选择-弹出层
            this.prop_prov_city_select_item.prov_id = prov_id;
            this.prop_prov_city_select_item.prov_name = prov_name;
            this.prop_prov_city_select_i ++;//弹出框是否可见
        },
        provCitySelectResult(prov_id,prov_name,citys){
            console.log("选择城市结果方法prov_id="+prov_id+",prov_name="+prov_name+",citys:",citys);
            var cityObj = {prov_name:prov_name,citys:citys};
            if(citys.length == 0){
                this.prov_city_select_map.delete(prov_id+'');
            }else{
                this.prov_city_select_map.set(prov_id+'',cityObj);//选中省市的数据 key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
            }
            if(this.form_data.send_pro == "0"){//选择的是全国的话,把值置空
                this.form_data.send_pro = "";
                this.form_data.send_pro_array = [];
            }else {
                var send_pros = this.form_data.send_pro.split(",");

                var send_pro_new = "";
                for(var i = 0;i<send_pros.length;i++){
                    var one = send_pros[i];
                    if(one != prov_id+""){
                        if(send_pro_new == ""){
                            send_pro_new = one+"";
                        }else{
                            send_pro_new += ","+one;
                        }
                    }
                }
                this.form_data.send_pro = send_pro_new;

            }
            console.log("(2)this.form_data.send_pro:"+this.form_data.send_pro);

            console.log("是否包含：",this.prov_city_select_map.get(prov_id+''));
           //console.log("是否包含：",this.prov_city_select_map.get(prov_id));

            this.$forceUpdate();//重新渲染vue
            

            // this.prov_city_select_map.forEach((value, key) => {
            //     console.log( "key:"+key," value:",value)
            // })
        },


        //获取公司列表数据
        getCompanyList(){
            //请求接口
            API.CompanyServlet({
                param: "selList",
                searchID: "",
            }).then((res) => {
                if (res.recode === 0) {
                    this.companyList = res.list;//公司列表数据-请求接口获取
                    var obj = {value:0,name:"请选择"};
                    this.companyList.unshift(obj);
                }
            });
        },



        //提交
        onSubmit(){
            console.log("提交");
            var submit_obj = {};

            if(this.form_data.oper_array.length == 0){
                this.$message.error("请选择可发的运营商");
                return;
            }else{
                submit_obj.yd_refuse = 1;
                submit_obj.lt_refuse = 1;
                submit_obj.dx_refuse = 1;
                if(this.form_data.oper_array.indexOf('0') > -1){
                    submit_obj.yd_refuse = 0;// 移动是否拒发，0：不拒发；1：拒发
                }
                if(this.form_data.oper_array.indexOf('1') > -1){
                    submit_obj.lt_refuse = 0;// 联通是否拒发，0：不拒发；1：拒发
                }
                if(this.form_data.oper_array.indexOf('2') > -1){
                    submit_obj.dx_refuse = 0;// 联通是否拒发，0：不拒发；1：拒发
                }
            }
            if(this.form_data.yld_level == -1){
                this.$message.error("请选择客户类型");
                return;
            }

             if(API.isEmtry(this.form_data.cpid)){
                this.$message.error("请填写账号");
                return;
            }

            if(this.form_data.user_opt_type == 0){// this.form_data.user_opt_type;//账号操作类别,0：新增；1：修改
                if(API.isEmtry(this.form_data.company_id) || this.form_data.company_id<=0){
                    this.$message.error("请选择客户所属的公司");
                    return;
                }
                
                if(API.isEmtry(this.form_data.login_pwd)){
                    this.$message.error("请填写登录密码");
                    return;
                }

            }
            



            submit_obj.user_opt_type = this.form_data.user_opt_type;//账号操作类别,0：新增；1：修改
            submit_obj.cpid = this.form_data.cpid;
            submit_obj.param="add_and_upd";

            if(this.form_data.user_opt_type == 0){//新增
                submit_obj.company_id = this.form_data.company_id;

                submit_obj.login_pwd = this.form_data.login_pwd;//用户登录密码
                submit_obj.user_type = this.form_data.user_type;//用户付费类型0:预付费类型1：后付费类型

                submit_obj.ip = this.form_data.ip;//客户鉴权ip地址(多个用半角逗号隔开)
                submit_obj.interface_type = this.form_data.interface_type;//接入方式，0:CMPP；1:HTTP
                submit_obj.qxt_http_type = this.form_data.qxt_http_type;//http对接类别表id,空表示没有,只有接入方式(interface_type)是http时显示 例如：zhe_jiang_cai_niao
                submit_obj.url = this.form_data.url;//回调地址
                submit_obj.mourl = this.form_data.mourl;//上行地址（即上行是回调用）
                submit_obj.sms_type = this.form_data.sms_type;// 客户短信类型，0:短信客户；1：语音客户 2：多媒体客户

                if(submit_obj.interface_type == 1){//接入方式，0:CMPP；1:HTTP
                    if(API.isEmtry(submit_obj.qxt_http_type)){
                        this.$message.error("请选择http对接协议");
                        return;
                    }
                }else{
                    if(submit_obj.interface_type == 0){//接入方式，0:CMPP；1:HTTP
                        submit_obj.qxt_http_type = "";//cmpp协议时，置空
                    }
                }

            }else{//修改

            }

            
            submit_obj.relationphone = this.form_data.relationphone;//联系人手机号码，多个用半角逗号隔开--核实 （注：在客户端用于下发短信的验证码使用）
            submit_obj.phones = this.form_data.phones;//余额提醒目标号码，多个用半角逗号隔开
            submit_obj.yld_level = this.form_data.yld_level;// 客户类型 ，默认为4，1：验证码； 2：行业通知；3：营销；4：四类
            submit_obj.send_pro = this.form_data.send_pro;// 允许发送的省份id0:全国； 多个省份id使用半角逗号隔开
            submit_obj.send_type = this.form_data.send_type;//发送类型，例如“三网行业”

            submit_obj.yd_price = this.form_data.yd_price;//移动价格
            submit_obj.lt_price = this.form_data.lt_price;//联通价格
            submit_obj.dx_price = this.form_data.dx_price;//电信价格

            var send_citys = [];
            this.prov_city_select_map.forEach((value, key) => {//key:省份ID  value:城市对象 {prov_name:省份名,citys:["保定", "唐山"]}
                console.log(key,value)
                var prov_id = key;
                var citys = value.citys;
                var city_array = [];
                for(var i =0;i<citys.length;i++){
                    var city_obj = {city:citys[i]};
                    city_array.push(city_obj);
                }

                var obj = {prov_id:prov_id,city_array:city_array};
                send_citys.push(obj);
            });
            submit_obj.send_citys = JSON.stringify(send_citys);

            API.UserServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    // this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}

/* element 单选框样式调整*/
.cur_dialog >>>.el-radio-group {
    line-height: 30px;
    vertical-align: bottom;
}

</style>